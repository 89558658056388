import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '@layouts/Layout';
import SEO from '@components/molecules/SEO';
import StatsAndPartnersSection from '@components/organisms/StatsAndPartnersSection';
import SubscriptionForm from '@components/molecules/Forms/SubscriptionForm';
import BonusSubImageDesktop from '@images/bonus_form_desktop.webp';
import BonusSubImageMobile from '@images/bonus_form_mobile.webp';
import HeroImage from '@images/help_page_hero.webp';
import HeroImageMobile from '@images/mobile_help_page_hero.webp';
import HeroSection from '@components/organisms/HeroSection';
import { contentSubscribeInputs } from '@helpers/constants';
import useToggle from '@hooks/useToggle';
import ActiveCardsSection from '@components/organisms/ActiveCardsSection';
import CalendarSection from '@components/organisms/CalendarSection';
import ImageDesktop from '@images/sub_form.png';
import ImageMobile from '@images/sub_form_mobile.png';
import TopFixedSection from '@components/organisms/TopFixedSection';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import { transformObject } from '@helpers/transformObject';
import { normalizeCampaigns } from '@normalizers/normalizeCampaigns';
import { motion } from 'framer-motion';
import GradientLine from '@components/molecules/GradientLine/GradientLine';

const GetHelpPage = (props) => {
  const {
    data: {
      allWp,
      wpPage: page,
      allWpPost,
      allWpCampaign,
      allWpCampaignCategory: { nodes },
      wp: {
        siteSettings: {
          siteSettings: {
            blogPostsActionButton,
            subFormErrorMessages,
            location: defaultLocation,
            menuText,
            menuLogo,
            cookiesUsage,
            registerForm,
            registerFormPrivacyPolicyLink,
            registerFormPrivacyPolicyText,
            registerFormTermsAndConditionsText,
            registerFormTitle,
            campaignsForm,
            campaignsFormErrorMessages,
            campaignsFormPrivacyPolicyLink,
            campaignsFormPrivacyPolicyText,
            campaignsFormTitle,
            ...subForm
          },
        },
      },
    },
  } = props;

  const {
    seo,
    uri,
    language,
    translations,
    template: { helpPageMeta },
  } = page;
  const currentLanguage = language ? language.slug : 'bg';
  const subFormCheckboxes = transformObject(subForm.subFormCheckboxesLabels);
  const { toggled, handleToggleClick } = useToggle(false);

  const isMobile = useBreakpoint(breakpoints.sm);

  const isMiddle = useBreakpoint(breakpoints.md);

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsRendered(true), 200);
  }, []);

  return (
    <Layout
      translations={translations}
      currentLanguage={currentLanguage}
      sideMenuContent={helpPageMeta.hpiSideMenuSection.menuItems}
    >
      <SEO
        title={seo?.title}
        uri={uri}
        headJson={seo?.headJSON}
        translations={translations}
        imageToPreload={isMobile ? HeroImageMobile : HeroImage}
      />
      <TopFixedSection
        menuText={menuText}
        menuLogo={menuLogo}
        handleToggleClick={handleToggleClick}
        toggled={toggled}
        generalSettingsUrl={allWp.nodes[0].allSettings.generalSettingsUrl}
      />
      <HeroSection
        dataId={1}
        content={helpPageMeta.hpiHeroSection}
        heroImage={HeroImage}
        heroImageMobile={HeroImageMobile}
        scrollTo="#causes-section"
        handleToggleClick={handleToggleClick}
        rightContent={{
          icon: 'icon-mail',
          text: helpPageMeta.hpiSubscribeToActiveCampaignsIconText.name,
          scrollTo: '#content-subscribe-form',
        }}
        toggled={toggled}
        withShareButton
        animateHero={props.location.state?.animate}
        Line={<GradientLine variant="help-one" />}
      />
      <StatsAndPartnersSection
        information={helpPageMeta.hpiCausesSection}
        dataId={2}
      />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ amount: 'some', once: true }}
      >
        <div className="relative">
          <ActiveCardsSection
            dataId={3}
            content={helpPageMeta.hpiCampaignsSection}
            cards={normalizeCampaigns(allWpCampaign)}
            id="campaigns-section"
          />
          {!isMiddle && isRendered && (
            <GradientLine
              variant="help-two"
              position="absolute top-[45%] left-0 w-[900px] lg:w-[1000px]"
            />
          )}
        </div>
        <SubscriptionForm
          id="help-subscribe-form"
          title={campaignsFormTitle}
          errors={campaignsFormErrorMessages}
          privacy={{
            text: campaignsFormPrivacyPolicyText,
            link: campaignsFormPrivacyPolicyLink.url,
            linkText: campaignsFormPrivacyPolicyLink.title,
          }}
          buttonText={campaignsForm.button}
          inputs={[
            { label: campaignsForm.firstName, type: 'text', name: 'firstName' },
            { label: campaignsForm.lastName, type: 'text', name: 'lastName' },
            { label: campaignsForm.email, type: 'text', name: 'email' },
            {
              label: 'Категории',
              type: 'select',
              name: 'select',
            },
          ]}
          list={nodes}
          imageDesktop={BonusSubImageDesktop}
          imageMobile={BonusSubImageMobile}
          checkBoxType="hidden"
        />
        <CalendarSection
          dataId={4}
          blogPostsActionButton={blogPostsActionButton}
          cards={allWpPost.nodes}
          content={helpPageMeta.hpiCalendarSection}
          initialSlide={0}
          id="calendar-section"
          selectCardStyles="regularCardStyles"
          withBiggerSpacing
          TopLine={
            <GradientLine
              variant="help-three"
              position="absolute top-[-147px] left-1/2"
            />
          }
          BottomLine={
            <GradientLine
              variant="help-four"
              position="absolute bottom-[-153px] left-1/2"
            />
          }
        />
        <SubscriptionForm
          isContentSubscribe
          id="content-subscribe-form"
          errors={subFormErrorMessages}
          title={subForm.subFormTitle}
          description={subForm.subFormText}
          privacy={{
            text: subForm.subFormDisclaimer.text,
            link: subForm.subFormDisclaimer.link.url,
            linkText: subForm.subFormDisclaimer.link.title,
          }}
          buttonText={subForm.subFormButtonText}
          defaultChecked={subFormCheckboxes.keys}
          inputs={contentSubscribeInputs}
          subFormCheckboxes={subFormCheckboxes.array}
          imageDesktop={ImageDesktop}
          imageMobile={ImageMobile}
          checkBoxType="checkbox"
        />
      </motion.div>
    </Layout>
  );
};

export default GetHelpPage;

export const query = graphql`
  query GET_HELP_PAGE($id: String!) {
    wpPage(id: { eq: $id }) {
      ...GetHelpPageContent
    }
    allWp {
      nodes {
        allSettings {
          generalSettingsUrl
        }
      }
    }
    allWpCampaign(sort: { fields: campaignMeta___endDate, order: ASC }) {
      nodes {
        campaignMeta {
          endDate
          startDate
          registerEndDate
          name
          type
        }
        databaseId
        uri
        campaignCategories {
          nodes {
            name
            slug
          }
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        postMeta {
          blogCardSize
          blogPostType
          blogPostStatus
        }
        uri
        categories {
          nodes {
            name
            slug
          }
        }
        date(formatString: "D/M/yyyy")
        title
        excerpt
        featuredImage {
          node {
            publicUrl
          }
        }
        id
      }
    }
    wp {
      ...DefaultLocationContent
      ...CookiesAndTermsContent
      siteSettings {
        siteSettings {
          blogPostsActionButton
          subFormErrorMessages {
            emptyField
            requiredField
          }
          cookiesUsage {
            text
            readMoreLink {
              title
              url
              target
            }
          }
          menuText
          menuLogo {
            altText
            mediaItemUrl
          }
          registerFormPrivacyPolicyLink {
            url
            title
          }
          campaignsForm {
            firstName
            lastName
            email
            button
            campaignType
          }
          campaignsFormErrorMessages {
            emptyField
            invalidField
            invalidPhone
            requiredField
          }
          campaignsFormPrivacyPolicyLink {
            target
            title
            url
          }
          campaignsFormPrivacyPolicyText
          campaignsFormTitle
        }
      }
      ...SubscriptionFormContent
    }
    allWpCampaignCategory {
      nodes {
        campaignsTaxonomyMeta {
          mailingListName
        }
        name
        slug
      }
    }
  }
`;
