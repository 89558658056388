import PropTypes from 'prop-types';
import styles from './StatsAndPartnersSection.module.scss';
import React, { Fragment, useRef } from 'react';
import { useHandleHover } from '@hooks/useHandleHover';
import Button from '@components/atoms/Buttons/Button';
import { useInView } from 'framer-motion';
import Image from '@components/molecules/ImageGatsby/Image';
import ShareButton from '@components/molecules/ShareButton/ShareButton';
import { formatStatisticsNumber } from '@helpers/formatStatisticsNumber';
import { useStatsAndPartnersSection } from './useStatsAndPartnersSection';
import { scrollToElement } from '@helpers/scrollToSection';

const StatsAndPartnersSection = ({ information, dataId }) => {
  const { isHovered, handleMouseOut, handleMouseOver } = useHandleHover();
  const ref = useRef(null);
  const isInView = useInView(ref);
  const infoRef = useRef(null);
  const isInfoInView = useInView(infoRef, { once: true });
  const {
    actionButton,
    description,
    name,
    statistics: { art, events, sport },
    title,
    partners,
  } = information;

  const { artNumber, sportNumber, eventsNumber } = useStatsAndPartnersSection(
    art,
    sport,
    events,
    isInView,
  );

  return (
    <div
      className="page-section mt-[60px] md:mt-[130px] lg:mt-[240px] xl:mt-[280px] relative z-[2]"
      data-id={dataId}
      id="causes-section"
    >
      <div
        ref={infoRef}
        className={`${styles.infoWrapper} ${
          isInfoInView ? 'in-view' : 'out-of-view'
        } transition-1000`}
      >
        <div className={styles.nameWrapper}>
          <p className="tag !ml-0">{name}</p>
          <div className="tag-line mt-[10px]" />
        </div>

        <div className={styles.descriptionWrapper}>
          <h2 className="h1">{title}</h2>
          <div className="relative z-[1]">
            <p>{description}</p>
          </div>
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            widthFull
            onClick={scrollToElement.bind(this, '#campaigns-section')}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          >
            {actionButton.title}
            <i
              className={`icon-interface-arrows-down ${styles.icon} ${
                isHovered ? 'top-1/2' : 'top-1/3'
              }`}
            />
          </Button>
        </div>
      </div>

      <div className={styles.statsWrapper} ref={ref}>
        <div className={styles.artistsWrapper}>
          <p
            data-attribute={formatStatisticsNumber(Math.round(artNumber))}
            className={styles.stats}
          >
            {formatStatisticsNumber(Math.round(artNumber))}
          </p>
          <p className={styles.statsLabels}>{art.title}</p>
        </div>

        <div className={styles.artistsWrapper}>
          <p
            data-attribute={formatStatisticsNumber(Math.round(eventsNumber))}
            className={styles.stats}
          >
            {formatStatisticsNumber(Math.round(eventsNumber))}
          </p>
          <p className={styles.statsLabels}>{events.title}</p>
        </div>

        <div className={styles.artistsWrapper}>
          <p
            data-attribute={formatStatisticsNumber(Math.round(sportNumber))}
            className={styles.stats}
          >
            {formatStatisticsNumber(Math.round(sportNumber))}
          </p>
          <p className={styles.statsLabels}>{sport.title}</p>
        </div>
      </div>

      <div className={styles.shareButton}>
        <ShareButton />
      </div>

      <div>
        {partners.map(({ media: { image, logo } }) => {
          return (
            <Fragment key={image.id}>
              <div className={styles.partnerImageWrapper}>
                <Image image={image.publicUrl} />
              </div>

              <div className={styles.partnerLogo}>
                <Image image={logo.publicUrl} />
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

StatsAndPartnersSection.propTypes = {
  dataId: PropTypes.number,
  information: PropTypes.shape({
    actionButton: PropTypes.shape({
      title: PropTypes.string,
    }),
    description: PropTypes.string,
    name: PropTypes.string,
    partners: PropTypes.array,
    title: PropTypes.string,
  }),
};

export default StatsAndPartnersSection;
