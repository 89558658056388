import { useEffect, useMemo, useState } from 'react';

const numbersTimeout = 100;

export const useStatsAndPartnersSection = (art, sport, events, isInView) => {
  const artNumberStep = useMemo(() => Math.round(art.number / 10), [
    art.number,
  ]);
  const [artNumber, setArtNumber] = useState(artNumberStep);

  const sportNumberStep = useMemo(() => Math.round(sport.number / 10), [
    sport.number,
  ]);
  const [sportNumber, setSportNumber] = useState(sportNumberStep);

  const eventsNumberStep = useMemo(() => Math.round(events.number / 10), [
    events.number,
  ]);
  const [eventsNumber, setEventsNumber] = useState(eventsNumberStep);

  const artNumberCounterEffect = () => {
    if (!isInView) return;
    let interval;
    if (art.number < artNumber + artNumberStep) {
      clearInterval(interval);
      setArtNumber(art.number);
      return;
    }
    interval = setInterval(() => {
      setArtNumber((prevCounter) =>
        prevCounter === art.number ? prevCounter : prevCounter + artNumberStep,
      );
    }, numbersTimeout);

    return () => {
      clearInterval(interval);
    };
  };
  useEffect(artNumberCounterEffect, [
    art.number,
    artNumber,
    isInView,
    artNumberStep,
  ]);

  const eventsNumberCounterEffect = () => {
    if (!isInView) return;
    let interval;
    if (events.number < eventsNumber + eventsNumberStep) {
      clearInterval(interval);
      setEventsNumber(events.number);
      return;
    }
    interval = setInterval(() => {
      setEventsNumber((prevCounter) =>
        prevCounter === events.number
          ? prevCounter
          : prevCounter + eventsNumberStep,
      );
    }, numbersTimeout);

    return () => {
      clearInterval(interval);
    };
  };
  useEffect(eventsNumberCounterEffect, [
    eventsNumber,
    events.number,
    isInView,
    eventsNumberStep,
  ]);

  const sportNumberCounterEffect = () => {
    if (!isInView) return;
    let interval;
    if (sport.number < sportNumber + sportNumberStep) {
      clearInterval(interval);
      setSportNumber(sport.number);
      return;
    }
    interval = setInterval(() => {
      setSportNumber((prevCounter) =>
        prevCounter === sport.number
          ? prevCounter
          : prevCounter + sportNumberStep,
      );
    }, numbersTimeout);

    return () => {
      clearInterval(interval);
    };
  };
  useEffect(sportNumberCounterEffect, [
    sport.number,
    sportNumber,
    isInView,
    sportNumberStep,
  ]);

  return { artNumber, sportNumber, eventsNumber };
};
